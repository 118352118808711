<template>
	<div>
		<div
			name="new-banner"
			class="row animated fadeIn"
			header-bg-variant="dark"
			header-text-variant="white h4">
			<b-card-body>
				<form
					@submit.prevent="createBannerPayload"
					@keydown="clearError($event.target.name);"
					@change="clearError($event.target.name);">
					<b-row>
						<b-col>
							<b-form-group
								label-for="title">
								<slot name="label">
									<label>
										{{ translate('title') }} <span class="text-danger">*</span>
									</label>
								</slot>
								<b-form-input
									id="title"
									v-model="form.title"
									:placeholder="translate('title')"
									:class="hasError('title') ? 'is-invalid' : ''"
									name="title"
									type="text" />
								<template v-if="hasError('title')">
									<span
										v-for="error in errors['title']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-form-group
								:errors="errors['image']"
								class="media-files"
								label-for="image">
								<slot name="label">
									<label>
										{{ translate('image') }} <span class="text-danger">*</span>
									</label>
								</slot>
								<b-form-file
									id="image"
									ref="image"
									:placeholder="translate('no_file')"
									:browse-text="translate('browse')"
									:class="hasError('image') ? 'is-invalid' : ''"
									class="text-truncate"
									name="image"
									type="file" />
								<b-form-text id="image">
									{{ translate('max', {size: '10'}) }} {{ translate('allowed_ext', {ext: imgExt }) }}
								</b-form-text>
								<template v-if="hasError('image')">
									<span
										v-for="error in errors['image']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-form-group
								:errors="errors['mobile_image']"
								class="media-files"
								label-for="mobile_image">
								<slot name="label">
									<label>
										{{ translate('mobile_image') }} <span class="text-danger">*</span>
									</label>
								</slot>
								<b-form-file
									id="mobile_image"
									ref="mobile_image"
									:placeholder="translate('no_file')"
									:browse-text="translate('browse')"
									:class="hasError('mobile_image') ? 'is-invalid' : ''"
									class="text-truncate"
									name="mobile_image"
									type="file" />
								<b-form-text id="mobile_image">
									{{ translate('max', {size: '10'}) }} {{ translate('allowed_ext', {ext: imgExt }) }}
								</b-form-text>
								<template v-if="hasError('mobile_image')">
									<span
										v-for="error in errors['mobile_image']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-form-group
								label-for="url">
								<slot name="label">
									<label>
										{{ translate('url') }}
									</label>
								</slot>
								<b-form-input
									id="url"
									v-model="form.url"
									:placeholder="translate('url')"
									:class="hasError('url') ? 'is-invalid' : ''"
									name="url"
									type="text" />
								<template v-if="hasError('url')">
									<span
										v-for="error in errors['url']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<div class="form-group">
								<div class="form-check">
									<input
										id="show_in_dashboard"
										v-model="form.show_in_dashboard"
										name="show_in_dashboard"
										class="form-check-input"
										type="checkbox">
									<label
										for="show_in_dashboard"
										class="label">
										{{ translate('show_in_dashboard') }}
									</label>
								</div>
								<template v-if="hasError('show_in_dashboard')">
									<span
										v-for="error in errors['show_in_dashboard']"
										:key="error"
										class="invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</div>
						</b-col>
					</b-row>
					<hr>
					<h3 class="mb-3">
						{{ translate('filters') }}
					</h3>
					<b-row>
						<b-col>
							<b-form-group class="form-selector">
								<slot name="user_types">
									<label>
										{{ translate('user_type') }}
									</label>
								</slot>
								<multiselect
									id="user_types"
									v-model="form.user_types"
									:options="userTypes"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:preselect-first="false"
									:placeholder="translate('select_user_type')"
									label="text"
									:custom-label="(option, key) => fixDiacriticCharacters(option[key])"
									track-by="value"
									name="user_types">
									<template slot="selection" />
									<template #option="{option}">
										{{ option.text }}
									</template>
								</multiselect>
								<b-form-text id="user_type">
									{{ translate('user_types_disclaimer_banner') }}
								</b-form-text>
								<template v-if="hasError('user_types')">
									<span
										v-for="error in errors['user_types']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group class="form-selector">
								<slot name="device_types">
									<label>
										{{ translate('device_types') }}
									</label>
								</slot>
								<multiselect
									id="device_types"
									v-model="form.device_type"
									:options="deviceTypes"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:preselect-first="false"
									:placeholder="translate('select_device_types')"
									label="text"
									:custom-label="(option, key) => fixDiacriticCharacters(option[key])"
									track-by="value"
									name="device_types">
									<template slot="selection" />
									<template #option="{option}">
										{{ option.text }}
									</template>
								</multiselect>
								<b-form-text id="user_type">
									{{ translate('device_types_disclaimer_banner') }}
								</b-form-text>
								<template v-if="hasError('device_type')">
									<span
										v-for="error in errors['device_type']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row v-if="isAdminOrCorporateWithoutCountry">
						<b-col>
							<b-form-group class="form-selector">
								<slot name="countries">
									<label>
										{{ translate('country') }}
									</label>
								</slot>
								<multiselect
									id="countries"
									v-model="form.countries"
									:options="countries"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:preselect-first="false"
									:placeholder="translate('country_placeholder')"
									label="text"
									:custom-label="(option, key) => fixDiacriticCharacters(option[key])"
									track-by="value"
									name="countries">
									<template slot="selection" />
									<template #option="{option}">
										{{ option.text }}
									</template>
								</multiselect>
								<b-form-text id="user_type">
									{{ translate('country_disclaimer_banner') }}
								</b-form-text>
								<template v-if="hasError('countries')">
									<span
										v-for="error in errors['countries']"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group>
								<slot name="priority">
									<label>
										{{ translate('priority') }}
									</label>
								</slot>
								<select
									id="priority"
									v-model="form.priority"
									:disabled=" (! Object.keys(priorities).length > 0) ? true : false"
									:class="hasError('priority') ? 'is-invalid' : ''"
									type="text"
									name="priority"
									class="form-control">
									<option
										value=""
										disabled>
										{{ translate('select_priority') }}
									</option>
									<option
										v-for="priority in priorities"
										:key="priority"
										:value="priority">
										{{ translate(priority) }}
									</option>
								</select>
								<template v-if="hasError('priority')">
									<span
										v-for="error in errors['priority']"
										:key="error"
										class="invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-form-group>
								<slot name="start_date">
									<label>
										{{ translate('start_date') }}
									</label>
								</slot>
								<div
									:class="hasError('start_date') ? 'is-invalid' : ''"
									:style="'height: auto'"
									class="form-control border-0">
									<div class="row custom-gutter">
										<div class="col-xl-2 col-sm-4">
											<select
												id="selectedStartYear"
												v-model="selectedStartYear"
												type="text"
												class="form-control w-100 my-1"
												name="year"
												@change="updateDays('start')">
												<option
													value=""
													selected
													disabled>
													{{ translate('year') }}
												</option>
												<option
													v-for="year in years"
													:key="year"
													:value="year">
													{{ year }}
												</option>
											</select>
										</div>
										<div class="col-xl-2 col-sm-4">
											<select
												id="selectedStartMonth"
												v-model="selectedStartMonth"
												type="text"
												class="form-control w-100 my-1"
												name="month"
												@change="updateDays('start')">
												<option
													value=""
													selected
													disabled>
													{{ translate('month') }}
												</option>
												<option
													v-for="month in months"
													:key="month"
													:value="month">
													{{ month }}
												</option>
											</select>
										</div>
										<div class="col-xl-2 col-sm-4">
											<select
												id="selectedStartDay"
												v-model="selectedStartDay"
												type="text"
												class="form-control w-100 my-1"
												name="day">
												<option
													value=""
													selected
													disabled>
													{{ translate('day') }}
												</option>
												<option
													v-for="day in startDays"
													:key="day"
													:value="day">
													{{ day }}
												</option>
											</select>
										</div>
										<div class="col-xl-2 col-md-3 col-sm-4">
											<select
												id="selectedStartHour"
												v-model="selectedStartHour"
												type="text"
												class="form-control w-100 my-1"
												name="hour">
												<option
													value=""
													selected
													disabled>
													{{ translate('hour') }}
												</option>
												<option
													v-for="hour in hours"
													:key="hour"
													:value="hour">
													{{ hour }}
												</option>
											</select>
										</div>
										<div class="col-xl-2 col-md-3 col-sm-4">
											<select
												id="selectedStartMinute"
												v-model="selectedStartMinute"
												type="text"
												class="form-control w-100 my-1"
												name="minute">
												<option
													value=""
													selected
													disabled>
													{{ translate('minute') }}
												</option>
												<option
													v-for="minute in minutes"
													:key="minute"
													:value="minute">
													{{ minute }}
												</option>
											</select>
										</div>
										<div
											v-if="form.start_date"
											class="alert alert-light col-12 col-md-6 col-lg-12 d-inline my-1 py-0 px-2 font-italic"
											role="alert">
											{{ form.start_date }}
										</div>
										<template v-if="hasError('start_date')">
											<span
												v-for="error in errors['start_date']"
												:key="error"
												class="custom-invalid-feedback animated fadeIn px-2"
												v-text="error" />
										</template>
									</div>
								</div>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group>
								<slot name="end_date">
									<label>
										{{ translate('end_date') }}
									</label>
								</slot>
								<div
									:class="hasError('end_date') ? 'is-invalid' : ''"
									:style="'height: auto'"
									class="form-control border-0">
									<div class="row custom-gutter">
										<div class="col-xl-2 col-sm-4">
											<select
												id="selectedEndYear"
												v-model="selectedEndYear"
												type="text"
												class="form-control w-100 my-1"
												name="year"
												@change="updateDays('end')">
												<option
													value=""
													selected>
													{{ translate('year') }}
												</option>
												<option
													v-for="year in years"
													:key="year"
													:value="year">
													{{ year }}
												</option>
											</select>
										</div>
										<div class="col-xl-2 col-sm-4">
											<select
												id="selectedEndMonth"
												v-model="selectedEndMonth"
												type="text"
												class="form-control w-100 my-1"
												name="month"
												@change="updateDays('end')">
												<option
													value=""
													selected>
													{{ translate('month') }}
												</option>
												<option
													v-for="month in months"
													:key="month"
													:value="month">
													{{ month }}
												</option>
											</select>
										</div>
										<div class="col-xl-2 col-sm-4">
											<select
												id="selectedEndDay"
												v-model="selectedEndDay"
												type="text"
												class="form-control w-100 my-1"
												name="day">
												<option
													value=""
													selected>
													{{ translate('day') }}
												</option>
												<option
													v-for="day in endDays"
													:key="day"
													:value="day">
													{{ day }}
												</option>
											</select>
										</div>
										<div class="col-xl-2 col-md-3 col-sm-4 d-inline">
											<select
												id="selectedEndHour"
												v-model="selectedEndHour"
												type="text"
												class="form-control w-100 my-1"
												name="hour">
												<option
													value=""
													selected>
													{{ translate('hour') }}
												</option>
												<option
													v-for="hour in hours"
													:key="hour"
													:value="hour">
													{{ hour }}
												</option>
											</select>
										</div>
										<div class="col-xl-2 col-md-3 col-sm-4 d-inline">
											<select
												id="selectedEndMinute"
												v-model="selectedEndMinute"
												type="text"
												class="form-control w-100 my-1"
												name="minute">
												<option
													value=""
													selected>
													{{ translate('minute') }}
												</option>
												<option
													v-for="minute in minutes"
													:key="minute"
													:value="minute">
													{{ minute }}
												</option>
											</select>
										</div>
										<div
											v-if="form.end_date"
											class="alert alert-light col-12 col-md-6 col-lg-12 d-inline my-1 py-0 px-2 font-italic"
											role="alert">
											{{ form.end_date }}
										</div>
										<template v-if="hasError('end_date')">
											<span
												v-for="error in errors['end_date']"
												:key="error"
												class="custom-invalid-feedback animated fadeIn px-2"
												v-text="error" />
										</template>
									</div>
								</div>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row class="mt-4 float-right">
						<b-col>
							<b-button
								:disabled="Object.keys(errors).length > 0 || loading"
								variant="primary"
								size="md"
								class="mr-2"
								type="submit">
								<template v-if="loading">
									<i class="fa fa-fw fa-spinner fa-pulse" />
									{{ translate('processing') }}
								</template>
								<template v-else>
									{{ translate('submit') }}
								</template>
							</b-button>
						</b-col>
					</b-row>
				</form>
			</b-card-body>
		</div>
	</div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import {
	Users,
	MediaLibrary as mediaTranslations,
	Banners as bannersTranslations,
	Countries as countriesTranslations,
	Time as timeTranslations,
} from '@/translations';
import {
	IMAGES_EXT as imgExt,
} from '@/settings/Banners';
import { superAdmin, corporate } from '@/settings/Roles';
import { YMDHMS_FORMAT, YM_FORMAT, SCHEDULE_START_YEAR } from '@/settings/Dates';
import { TIMEZONE_NAME } from '@/settings/General';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
	name: 'BannerData',
	messages: [
		Users,
		mediaTranslations,
		bannersTranslations,
		countriesTranslations,
		timeTranslations,
	],
	components: { Multiselect },
	props: {
		formImport: {
			type: Object,
			default() {
				return {};
			},
		},
		errorsImport: {
			type: Object,
			default() {
				return {};
			},
		},
		validUserTypes: {
			type: Array,
			default() {
				return [];
			},
		},
		validCountries: {
			type: Array,
			default() {
				return [];
			},
		},
		validDeviceTypes: {
			type: Array,
			default() {
				return [];
			},
		},
		priorities: {
			type: Object,
			default() {
				return {};
			},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		bannerImport: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			form: this.formImport,
			errors: this.errorsImport,
			superAdmin,
			corporate,
			countries: [],
			countriesOnly: [],
			userTypes: [],
			userTypesOnly: [],
			deviceTypes: [],
			deviceTypesOnly: [],
			imgExt,
			years: [],
			months: [],
			startDays: [],
			endDays: [],
			hours: [],
			minutes: [],
			selectedStartYear: '',
			selectedStartMonth: '',
			selectedStartDay: '',
			selectedStartHour: '',
			selectedStartMinute: '',
			selectedEndYear: '',
			selectedEndMonth: '',
			selectedEndDay: '',
			selectedEndHour: '',
			selectedEndMinute: '',
			YMDHMS_FORMAT,
			YM_FORMAT,
			TZN: TIMEZONE_NAME,
		};
	},
	computed: {
		isAdminOrCorporateWithoutCountry() {
			return this.superAdmin.includes(this.$user.details().type) || (this.corporate.includes(this.$user.details().type) && !this.$user.details().country);
		},
		start_date() {
			if (this.selectedStartYear !== '' && this.selectedStartMonth !== '' && this.selectedStartDay !== '' && this.selectedStartHour !== '' && this.selectedStartMinute !== '') {
				return this.createDate(this.selectedStartYear, this.selectedStartMonth, this.selectedStartDay, this.selectedStartHour, this.selectedStartMinute);
			}
			return '';
		},
		end_date() {
			if (this.selectedEndYear !== '' && this.selectedEndMonth !== '' && this.selectedEndDay !== '' && this.selectedEndHour !== '' && this.selectedEndMinute !== '') {
				return this.createDate(this.selectedEndYear, this.selectedEndMonth, this.selectedEndDay, this.selectedEndHour, this.selectedEndMinute);
			}
			return '';
		},
		hasData() {
			return Object.values(this.bannerImport).length > 0;
		},
	},
	watch: {
		'form.countries': function countries() {
			const countriesSelect = this.form.countries;
			// no need to reassign 'select' if it's already equal to all of the countries
			if (this.handleSelectAll(countriesSelect, 'countries') === true && countriesSelect !== this.countriesOnly) {
				this.form.countries = this.countriesOnly;
			}
		},
		'form.user_types': function userTypes() {
			const userTypesSelect = this.form.user_types;
			// no need to reassign 'select' if it's already equal to all of the countries
			if (this.handleSelectAll(userTypesSelect, 'user_types') === true && userTypesSelect !== this.userTypesOnly) {
				this.form.user_types = this.userTypesOnly;
			}
		},
		'form.device_type': function deviceTypes() {
			const deviceTypesSelect = this.form.device_type;
			// no need to reassign 'select' if it's already equal to all of the countries
			if (this.handleSelectAll(deviceTypesSelect, 'device_types') === true && deviceTypesSelect !== this.deviceTypesOnly) {
				this.form.device_type = this.deviceTypesOnly;
			}
		},
		language() {
			this.initializeMultiselect();
			if (this.form.countries.length > 0) {
				this.form.countries = this.form.countries.map((item) => ({
					value: item.value,
					text: this.translate(item.value.toLowerCase()),
				}));
			}

			if (this.form.user_types.length > 0) {
				this.form.user_types = this.form.user_types.map((item) => ({
					value: item.value,
					text: this.translate(item.value.toLowerCase()),
				}));
			}

			if (this.form.device_type.length > 0) {
				this.form.device_type = this.form.device_type.map((item) => ({
					value: item.value,
					text: this.translate(item.value.toLowerCase()),
				}));
			}
		},
		start_date(newVal) {
			this.form.start_date = newVal;
			this.clearError('start_date');
		},
		end_date(newVal) {
			this.form.end_date = newVal;
			if (typeof newVal === 'undefined') {
				this.form.end_date = '';
			}
			this.clearError('end_date');
		},
		errorsImport(value) {
			this.errors = value;
		},
		bannerImport() {
			this.fetchFormData();
		},
	},
	mounted() {
		this.populateTime();
		if (this.bannerImport.length) this.fetchFormData();
		this.initializeMultiselect();
	},
	methods: {
		createBannerPayload() {
			const formData = new FormData();
			this.form.image = undefined;
			if (typeof this.$refs.image !== 'undefined') {
				const [File] = this.$refs.image.$refs.input.files;
				this.form.image = File;
			}

			if (typeof this.$refs.mobile_image !== 'undefined') {
				const [File] = this.$refs.mobile_image.$refs.input.files;
				this.form.mobile_image = File;
			}
			// Required fields
			formData.set('title', this.form.title);
			formData.set('show_in_dashboard', this.form.show_in_dashboard ? 1 : 0);
			if (typeof this.form.image !== 'undefined') {
				formData.set('image', this.form.image);
			}
			if (typeof this.form.mobile_image !== 'undefined') {
				formData.set('mobile_image', this.form.mobile_image);
			}
			if (this.form.start_date) {
				formData.set('start_date', this.form.start_date);
			} else {
				formData.set('start_date', this.$moment().tz(this.TZN).format(this.YMDHMS_FORMAT));
			}
			// Optional fields
			if (this.form.url) formData.set('url', this.form.url);
			if (this.form.priority) formData.set('priority', this.form.priority);
			if (this.form.user_types) formData.set('user_types', this.form.user_types);
			if (this.form.end_date) formData.set('end_date', this.form.end_date);
			if (Object.keys(this.form.countries).length) formData.set('countries', this.isAdminOrCorporateWithoutCountry ? this.getCountriesIso(this.form.countries) : this.country);
			if (Object.keys(this.form.user_types).length) formData.set('user_types', this.getUserTypes(this.form.user_types));
			if (Object.keys(this.form.device_type).length) formData.set('device_type', this.getDeviceTypes(this.form.device_type));
			this.createBanner(formData);
		},
		getCountriesIso(select) {
			const selectedIsoCodes = select.map((countryAttributes) => {
				if (countryAttributes.value === 'ALL') {
					return this.countries.map((country) => country.value);
				}
				return countryAttributes.value;
			}).toString();
			return selectedIsoCodes;
		},
		getUserTypes(select) {
			const selectedUserTypes = select.map((userTypes) => {
				if (userTypes.value === 'ALL') {
					return this.userTypes.map((userType) => userType.value);
				}
				return userTypes.value;
			}).toString();
			return selectedUserTypes;
		},
		getDeviceTypes(select) {
			const selectedDeviceTypes = select.map((deviceTypes) => {
				if (deviceTypes.value === 'ALL') {
					return this.deviceTypes.map((deviceType) => deviceType.value);
				}
				return deviceTypes.value;
			}).toString();
			return selectedDeviceTypes;
		},
		createBanner(formData) {
			this.$emit('requestData', formData);
		},
		hasError(field) {
			if (typeof this.errors[field] !== 'undefined') { return true; }
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
		// Dates
		populateTime() {
			const startYear = this.$moment(`${SCHEDULE_START_YEAR}`).year();
			for (let i = 0; i <= 59; i += 1) {
				if (i <= 20) this.years.push(startYear + i);
				if (i < 10) {
					this.hours.push(`0${i}`);
					this.minutes.push(`0${i}`);
					if (i > 0) this.months.push(`0${i}`);
				} else {
					if (i <= 23) this.hours.push(`${i}`);
					if (i <= 12) this.months.push(`${i}`);
					this.minutes.push(`${i}`);
				}
			}
		},
		updateDays(id) {
			let year = '';
			let month = '';

			const days = [];
			if (id === 'start') {
				this.startDays = [];
				year = this.selectedStartYear;
				month = this.selectedStartMonth;
			} else if (id === 'end') {
				this.endDays = [];
				year = this.selectedEndYear;
				month = this.selectedEndMonth;
			}
			const maxDaysInMonth = this.$moment(`${year}-${month}`, this.YM_FORMAT).daysInMonth();
			for (let i = 1; i <= maxDaysInMonth; i += 1) {
				if (i < 10) {
					days.push(`0${i}`);
				} else {
					days.push(`${i}`);
				}
			}
			if (id === 'start') {
				this.startDays = days;
				this.selectedStartDay = '01';
			} else if (id === 'end') {
				this.endDays = days;
				this.selectedEndDay = '01';
			}
		},
		createDate(year, month, day, hour, minute) {
			if (year !== +'' && month !== +'' && day !== +'' && hour !== +'' && minute !== +'') {
				return this.$moment(`${year}-${month}-${day} ${hour}:${minute}:00.000000`).format(this.YMDHMS_FORMAT);
			}

			return '';
		},
		// Multiselect
		initializeMultiselect() {
			// Countries
			this.countries = this.validCountries.map((item) => ({
				value: item,
				text: this.translate(item.toLowerCase()),
			}));
			this.countriesOnly = [...this.countries];
			this.countries.unshift({ value: 'ALL', text: this.translate('all_countries') });
			this.form.countries = this.searchSelectValues(this.form.countries, this.countriesOnly);

			// User Types
			this.userTypes = this.validUserTypes.map((item) => ({
				value: item,
				text: this.translate(item.toLowerCase()),
			}));
			this.userTypesOnly = [...this.userTypes];
			this.userTypes.unshift({ value: 'ALL', text: this.translate('all_user_types') });
			this.form.user_types = this.searchSelectValues(this.form.user_types, this.userTypesOnly);

			// Device Types
			this.deviceTypes = this.validDeviceTypes.map((item) => ({
				value: item,
				text: this.translate(item.toLowerCase()),
			}));
			this.deviceTypesOnly = [...this.deviceTypes];
			this.deviceTypes.unshift({ value: 'ALL', text: this.translate('all_user_types') });
			this.form.device_type = this.searchSelectValues(this.form.device_type, this.deviceTypesOnly);
		},
		handleSelectAll(select, field) {
			let allSelected = false;
			if (typeof select[0] === 'undefined') return false;

			if (field === 'countries') {
				const selectedCountries = select.map((country) => country.value);
				const numberOfCountries = this.countriesOnly.length;
				const selectAllExists = (this.countries[0].value === 'ALL');

				if (selectedCountries.includes('ALL') || selectedCountries.length === numberOfCountries) {
					if (selectAllExists) {
						this.countries.shift();
					}
					allSelected = true;
				}
				if ((!selectAllExists) && (selectedCountries.length !== numberOfCountries)) {
					this.countries.unshift({ value: 'ALL', text: this.translate('all_countries') });
				}
			}

			if (field === 'user_types') {
				const selectedUserTypes = select.map((userType) => userType.value);
				const numberOfUserTypes = this.userTypesOnly.length;
				const selectAllExists = (this.userTypes[0].value === 'ALL');

				if (selectedUserTypes.includes('ALL') || selectedUserTypes.length === numberOfUserTypes) {
					if (selectAllExists) {
						this.userTypes.shift();
					}
					allSelected = true;
				}
				if ((!selectAllExists) && (selectedUserTypes.length !== numberOfUserTypes)) {
					this.userTypes.unshift({ value: 'ALL', text: this.translate('all_user_types') });
				}
			}

			if (field === 'device_types') {
				const selectedDeviceTypes = select.map((deviceType) => deviceType.value);
				const numberOfDeviceTypes = this.deviceTypesOnly.length;
				const selectAllExists = (this.deviceTypes[0].value === 'ALL');

				if (selectedDeviceTypes.includes('ALL') || selectedDeviceTypes.length === numberOfDeviceTypes) {
					if (selectAllExists) {
						this.deviceTypes.shift();
					}
					allSelected = true;
				}
				if ((!selectAllExists) && (selectedDeviceTypes.length !== numberOfDeviceTypes)) {
					this.deviceTypes.unshift({ value: 'ALL', text: this.translate('all_user_types') });
				}
			}

			return allSelected;
		},
		searchSelectValues(selectValue, searchIn) {
			if (typeof selectValue !== 'string') { return selectValue; }
			let values = [];
			let item;
			const arrValues = selectValue.split(',');

			arrValues.forEach((value) => {
				item = searchIn.find((option) => option.value === value);
				if (typeof item !== 'undefined') values.push(item);
			});

			if (values.length === 0) {
				values = '';
			}

			return values;
		},
		fetchFormData() {
			Object.keys(this.bannerImport).forEach((keyValue) => {
				this.form[keyValue] = this.bannerImport[keyValue];
				if (keyValue === 'start_date') {
					let formatDate = this.dateFormat(this.bannerImport[keyValue]);
					if (typeof this.bannerImport[keyValue].date !== 'undefined') {
						formatDate = this.dateFormat(this.bannerImport[keyValue].date);
					}
					this.selectedStartYear = formatDate.year;
					this.selectedStartMonth = formatDate.month;
					this.updateDays('start');
					this.selectedStartDay = formatDate.day;
					this.selectedStartHour = formatDate.hour;
					this.selectedStartMinute = formatDate.minute;
				} else if (keyValue === 'end_date' && this.bannerImport[keyValue]) {
					let formatDate = this.dateFormat(this.bannerImport[keyValue]);
					if (typeof this.bannerImport[keyValue].date !== 'undefined') {
						formatDate = this.dateFormat(this.bannerImport[keyValue].date);
					}
					this.selectedEndYear = formatDate.year;
					this.selectedEndMonth = formatDate.month;
					this.updateDays('end');
					this.selectedEndDay = formatDate.day;
					this.selectedEndHour = formatDate.hour;
					this.selectedEndMinute = formatDate.minute;
				} else if (keyValue === 'countries' && this.bannerImport[keyValue]) {
					this.form[keyValue] = this.searchSelectValues(this.form[keyValue], this.countriesOnly);
				} else if (keyValue === 'user_types' && this.bannerImport[keyValue]) {
					this.form[keyValue] = this.searchSelectValues(this.form[keyValue], this.userTypesOnly);
				} else if (keyValue === 'device_type' && this.bannerImport[keyValue]) {
					this.form[keyValue] = this.searchSelectValues(this.form[keyValue], this.deviceTypesOnly);
				}
			});
		},
		dateFormat(date) {
			const formatDate = {};
			formatDate.year = this.$moment(date).format('YYYY');
			formatDate.month = this.$moment(date).format('MM');
			formatDate.day = this.$moment(date).format('DD');
			formatDate.hour = this.$moment(date).format('HH');
			formatDate.minute = this.$moment(date).format('mm');
			return formatDate;
		},
	},
};
</script>
<style scoped>
.custom-gutter > [class*='col-'] {
	padding-right:4px;
	padding-left:4px;
}
</style>
